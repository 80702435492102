<template lang="pug">
  v-container#invmod(fluid='' tag='section')
    v-snackbar(v-model='snackbar.show' :timeout='5000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
    //-     v-icon mdi-close
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title
          h4 Transacciones
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        v-form(ref='formTransaction' v-model='validTransaction' lazy-validation='' autocomplete="random-string")
          v-container.py-0
            v-row
              v-col(cols="12")
                v-alert(v-model="alertData" dismissible dense='' border='left' type='info')
                  | Si está obligado a llevar contabilidad debe tener cuidado de modificar los datos del inventario sin una transacción de venta o compra.
              v-col(cols='12' sm='6' md='3')
                v-menu(v-model='dateMenu1' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='290px')
                  template(v-slot:activator='{ on, attrs }')
                    v-text-field(readonly v-model='transaction.transactionDate' label='Fecha de transacción' prepend-icon='mdi-calendar' v-bind='attrs' v-on='on')
                  v-date-picker(v-model='transaction.transactionDate' @input='dateMenu1 = false' :readonly='chargedTransaction === true' style='margin: 0;' color="green lighten-1" no-title='')
              v-col(cols='12' sm='6' md='2')
                v-select(v-model='transaction.transactionType' :items='transactionTypes' label='Tipo' :rules="requeridos" :readonly='chargedTransaction === true')
              v-col(cols='12' sm='6' md='2')
                v-text-field(v-model='transaction.transactionQuantity' :rules="requeridos" label='Cantidad' placeholder='Cantidad' type="text" @keypress="isNumber($event)" :readonly='chargedTransaction === true')
              v-col(cols='12' sm='6' md='5')
                v-text-field(v-model='transaction.description' label='Descripción' placeholder='Descripción' :readonly='chargedTransaction === true')
            v-row
              v-col(cols="12" md="6")
                v-divider.mx-4(inset="" vertical="")
                //- *************************** Buscador ***************************
                v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
              v-col(cols="12" md="3" style='display:flex; justify-content:center;')
                v-btn.mr-0(color='blue' @click='newTransaction' style="width: 80%;" :loading="loadingSave")
                  | NUEVO
              v-col(cols="12" md="3" style='display:flex; justify-content:center;')
                v-btn.mr-0(color='primary' @click='saveTransaction' style="width: 80%;" :loading="loadingSave")
                  | GUARDAR
          v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
          v-data-table.mx-2#tablaDatos(:headers="headers" v-show="!firstLoad" :items="transactionLst" item-key="uid" :search="search" dense fixed-header :loading="cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Transacciones por página'\}")
            template(v-slot:item.documentIdVal="{ item }")
              label(style="color: #804100; font-weight: bold;") {{item.documentId}}
            template(v-slot:item.charge="{ item }")
              v-tooltip(bottom="")
                template(v-slot:activator="{ on }")
                  v-btn(text="" icon="" color='blue' v-on="on" @click="chargeTransaction(item)")
                    v-icon mdi-eye
                span.tooltips Cargar Datos
            //- Mensaje de alerta cuando no hay resultados
            v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
              | Sin resultados
            v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
              | Sin datos
            v-flex(xs12='', sm10='', offset-sm1='')
</template>

<script>

  export default {
    props: {
      productToTransaction: {
        default: function () {
          return {
            codArticle: '',
            barcode: '',
            name: '',
            description: '',
            type: {
              codigo: '01',
              texto: 'BIEN',
            },
            quantity: '',
            activeValue: '',
            productKey: '',
            completeData: '',
            tarifaiva: {
              codigo: '0',
              texto: '0%',
              valor: '0',
            },
            irbpnr: '',
            tarifaice: '',
            totalValue: '',
          }
        },
        type: Object,
      },
    },
    data: () => ({
      alertData: true,
      chargedTransaction: false,
      headers: [
        { text: 'Id - Documento', value: 'documentIdVal', align: 'left', filterable: true },
        { text: 'Tipo de transacción', value: 'transactionType', align: 'left', filterable: true },
        { text: 'Descripción', value: 'description', align: 'left', filterable: true },
        { text: 'Fecha de transacción', value: 'transactionDate', align: 'left', filterable: true },
        { text: 'Cantidad', value: 'transactionQuantity', align: 'left', filterable: true },
        { text: 'Cargar datos', align: 'center', value: 'charge', sortable: false }, // No se reordena
      ],
      ordenarPor: 'codArticle',
      firstLoad: false,
      cargando: false,
      search: '',
      transaction: {
        transactionType: '',
        productId: '',
        description: '',
        transactionQuantity: null,
        transactionDate: new Date().toISOString().substr(0, 10),
        activeValue: '',
      },
      transactionTypes: ['Ingreso', 'Salida'],
      // variables para la seleccion de fechas
      dateMenu1: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      loadingSave: false,
      validTransaction: true,
      requeridos: [
        value => !!value || 'Requerido',
      ],
    }),
    computed: {
      transactionLst () {
        return this.$store.state.transaction.lstTransactions
      },
    },
    watch: {
      productToTransaction () {
        this.transaction.productId = this.productToTransaction.productKey
        this.transaction.activeValue = this.productToTransaction.activeValue
        this.$store.dispatch('transaction/loadTransactions', this.productToTransaction.productKey)
      },
    },
    mounted () {
      this.alertData = true
      this.transaction.productId = this.productToTransaction.productKey
      this.transaction.activeValue = this.productToTransaction.activeValue
      this.$store.dispatch('transaction/loadTransactions', this.productToTransaction.productKey)
    },
    methods: {
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      chargeTransaction (transactionSelected) {
        this.transaction = transactionSelected
        this.chargedTransaction = true
      },
      newTransaction () {
        this.chargedTransaction = false
        this.transaction = {
          transactionType: '',
          productId: this.productToTransaction.productKey,
          description: '',
          transactionQuantity: null,
          transactionDate: new Date().toISOString().substr(0, 10),
        }
      },
      async saveTransactionToBD () {
        this.$emit('productToInventory', this.productToTransaction)
        await this.$store.dispatch('transaction/saveTransaction', this.transaction).then(async resolve => {
          this.transaction = {
            transactionType: '',
            productId: this.productToTransaction.productKey,
            description: '',
            transactionQuantity: null,
            transactionDate: new Date().toISOString().substr(0, 10),
          }
          this.$emit('closeModal')
        }, reject => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al guardar la transacción',
          }
        })
      },
      saveTransaction () {
        if (this.$refs.formTransaction.validate()) {
          if (this.chargedTransaction === false) {
            const totalProducts = parseInt(this.productToTransaction.quantity)
            const newQuantity = parseInt(this.transaction.transactionQuantity)
            if (this.transaction.transactionType === 'Salida' && totalProducts >= newQuantity) {
              // se resta y se  guardan los datos en la BD
              this.productToTransaction.quantity = totalProducts - newQuantity
              this.productToTransaction.totalValue = parseInt(this.productToTransaction.quantity) * parseFloat(this.transaction.activeValue)
              this.saveTransactionToBD()
            } else if (this.transaction.transactionType === 'Ingreso') {
              // se suma y se guardan los datos en la BD
              this.productToTransaction.quantity = totalProducts + newQuantity
              this.productToTransaction.totalValue = parseInt(this.productToTransaction.quantity) * parseFloat(this.transaction.activeValue)
              this.saveTransactionToBD()
            } else {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'La cantidad de salida no puede ser mayor que la existente',
              }
            }
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'No se puede editar una transacción',
            }
          }
        }
      },
    },
  }
</script>
